import { Box, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Style from "../../styles/resourse.module.scss";
import ABoustUsImg from "../../assets/png/aboutImg1.png";
import BlogsImg from "../../assets/png/blogImg2.png";
import FaQImg from "../../assets/png/faqImg1.png";
import TCImg from "../../assets/png/termsCondImg1.png";
import { useSelector } from "react-redux";

const infoAboutUs = [
  { title: "Who are we?", route: "/aboutUs" },
  { title: "Our Work", route: "/aboutUs/ourWork" },
  { title: "Services", route: "/aboutUs/services" },
  { title: "Get in touch", route: "/aboutUs/getInTouch" },
];

const Resources = ({ open, setOpen }) => {
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);
  const handleClick = (e, route) => {
    e.preventDefault();
    navigate(route);
    handleClose();
  };

  const getBlogTopCategoriesSelector = useSelector(
    (state) => state?.blogTopCategories
  );
  const { result } = getBlogTopCategoriesSelector;

  const getFaqSelector = useSelector((state) => state?.blogTopCategories);
  const { result: faqs } = getBlogTopCategoriesSelector;

  return (
    <Modal open={open} onClose={handleClose} className="modal">
      <Grid container className={Style.main}>
        <Grid
          className={Style.main_}
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          // onClick={() => {
          //   navigate("/aboutUs");
          //   handleClose();
          // }}
        >
          <Typography sx={{ my: 2, paddingTop: "5px", paddingBottom: "5px" }}>
            About us
          </Typography>
          <img src={ABoustUsImg} alt="" className={Style.img} />
          {infoAboutUs.map((item, idx) => (
            <Link className="link" key={idx}>
              <Typography
                onClick={(e) => handleClick(e, item.route)}
                variant="subtitle2"
                sx={{ mt: 2 }}
              >
                {item.title}
              </Typography>
            </Link>
          ))}
        </Grid>

        <Grid className={Style.main_} item xs={12} sm={4} md={3} lg={3}>
          <Typography sx={{ my: 2, paddingTop: "5px", paddingBottom: "5px" }}>
            Blogs & vlogs
          </Typography>
          <img src={BlogsImg} alt="" className={Style.img} />
          <Link className="link" to={`/blogs`}>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              All blogs
            </Typography>
          </Link>
          {result.length ? (
            result.map((cat) => (
              <Link className="link" to={`/blogs?category=${cat._id}`}>
                <Typography variant="subtitle2" sx={{ mt: 2 }}>
                  {cat.title}
                </Typography>
              </Link>
            ))
          ) : (
            <Link className="link" to={"/blogs"}>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                Go to blogs
              </Typography>
            </Link>
          )}
        </Grid>

        <Grid
          className={Style.main_}
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          onClick={() => {
            navigate("/faqs");
            handleClose();
          }}
        >
          <Typography sx={{ my: 2, paddingTop: "5px", paddingBottom: "5px" }}>
            FAQs
          </Typography>
          <img src={FaQImg} alt="" className={Style.img} />
          <Link className="link">
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              What are 3D Collectible Learning Blocks?
            </Typography>
          </Link>
          <Link className="link">
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              How do CLB work and how to collect them?
            </Typography>
          </Link>
          <Link className="link">
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              What is AR/VR immersive learning?
            </Typography>
          </Link>
          <Link className="link">
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              What are the benefits of AR/VR immersive learning?
            </Typography>
          </Link>
        </Grid>

        <Grid className={Style.main_} item xs={12} sm={4} md={3} lg={3}>
          <Typography sx={{ my: 2, paddingTop: "5px", paddingBottom: "5px" }}>
            Learn
          </Typography>
          <img src={TCImg} alt="" className={Style.img} />
          <Link
            to="/termsCondtion"
            // onClick={() => {
            //   alert("asdf");
            //   navigate("/termsCondtion");
            //   handleClose();
            // }}
            className="link"
          >
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Terms & Conditions
            </Typography>
          </Link>
          <Link to="/privacy" className="link">
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Privacy Policy
            </Typography>
          </Link>
          <Link className="link" to="/feedback">
            <Typography
              variant="subtitle2"
              sx={{ mt: 2 }}
              // onClick={() => {
              //   navigate("/feedback");
              //   handleClose();
              // }}
            >
              Feedback
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default Resources;
