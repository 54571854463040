import axios from "axios";
import { toast } from "react-hot-toast";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    if (response?.config?.method !== "get" && response.status !== 204)
      toast?.success(response?.data?.message, {
        duration: 5000,
        position: "bottom-left",
      });
    return response;
  },
  function (error) {
    if (error?.response?.config?.method != "get")
      toast?.error(error?.response?.data?.message, {
        duration: 5000,
        position: "bottom-left",
      });
    return Promise.reject(error);
  }
);

export default instance;
