import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BuyCLBConfimation from "../../dialog/BuyCLBConfimation";
import { getAllCartAPI } from "../../redux/cart/getAllCart";
import { clearCartAPI } from "../../redux/category/clearCart";
import Style from "../../styles/cart.module.scss";
import { clearSingleCartAPI } from "../../redux/category/clearSingleCart";
import NoCart from "./NoCart";
import LoaderUI from "../../ui/LoaderUI";

export default function Cart({ open, setOpen }) {
  const getAllCartSelector = useSelector((state) => state?.getAllCart);
  const { result, loading } = getAllCartSelector;

  const dispatch = useDispatch();

  const [buyCLBs, setBuyCLBs] = useState();
  const [selectedCLBs, setSelectedCLBs] = useState([]);

  const handleClose = () => {
    // alert("asdf");
    setOpen(false);
  };

  const handleDeleteCartItme = async (clbId) => {
    await dispatch(clearSingleCartAPI(clbId));
    const cart = await dispatch(getAllCartAPI());
    setSelectedCLBs([...cart?.payload?.result]);
  };

  const handleCartFetch = async () => {
    const cart = await dispatch(getAllCartAPI());
    setSelectedCLBs([...cart?.payload?.result]);
  };

  const handleClearCart = async () => {
    await dispatch(clearCartAPI());
    await dispatch(getAllCartAPI());
    setSelectedCLBs([]);
  };

  useEffect(() => {
    handleCartFetch();
  }, []);

  return (
    <>
      <Modal open={open} onClose={handleClose} className="modal">
        <Grid container justifyContent="center" sx={{ p: 2 }}>
          <Grid lg={4} md={7} sm={9} xs={12}>
            <Card>
              <Stack spacing={2}>
                <Stack direction="row" sx={{ my: 2 }}>
                  <Typography align="center" variant="h5" sx={{ flex: 1 }}>
                    Cart
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                {loading ? (
                  <LoaderUI />
                ) : result?.length ? (
                  <>
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>
                        Total Items: {selectedCLBs?.length}
                      </Typography>
                      <Button onClick={handleClearCart}>Clear All</Button>
                    </Stack>
                    {selectedCLBs?.map((elem, idx) => {
                      return (
                        <Box key={idx} className={Style?.cartItem}>
                          <Stack
                            direction="row"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            spacing={2}
                          >
                            {elem?.clb?.type == "2d" && (
                              <Avatar
                                variant="rounded"
                                src={elem?.clb?.files?.[0]}
                                alt="clb image"
                                sx={{ height: 100, width: 100 }}
                              />
                            )}
                            {elem?.clb?.type == "3d" && (
                              <model-viewer
                                style={{ width: 120, height: 100 }}
                                src={elem?.clb?.files?.[0]}
                                ar-modes="webxr scene-viewer quick-look"
                                camera-controls
                                poster="poster.webp"
                                shadow-intensity="1"
                                disable
                              ></model-viewer>
                            )}

                            {/* */}
                            <Box>
                              <Typography className={Style?.title}>
                                {elem?.clb?.title}
                              </Typography>
                              <Typography className={Style?.bodyText}>
                                {elem?.clb?.creator?.name}
                              </Typography>
                              <Typography className={Style?.bodyText}>
                                {elem?.clb?.category?.title}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography className={Style?.title}>
                                {" "}
                                ₹ {elem?.clb?.cost?.inr}
                              </Typography>
                              <Typography className={Style?.title}>
                                {" "}
                                $ {elem?.clb?.cost?.usd}
                              </Typography>
                            </Box>
                            <IconButton
                              onClick={() =>
                                handleDeleteCartItme(elem?.clb?._id)
                              }
                            >
                              <CloseIcon />
                            </IconButton>
                          </Stack>
                        </Box>
                      );
                    })}
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      sx={{ my: 2 }}
                    >
                      <Typography className={Style?.title}>
                        Cart Total
                      </Typography>
                      <Box>
                        <Typography className={Style?.title}>
                          {" "}
                          ₹{" "}
                          {result?.reduce(
                            (initial, elem) => initial + elem?.clb?.cost?.inr,
                            0
                          )}
                        </Typography>
                        <Typography className={Style?.title}>
                          {" "}
                          ${" "}
                          {result?.reduce(
                            (initial, elem) => initial + elem?.clb?.cost?.usd,
                            0
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                    <Button
                      onClick={() => setBuyCLBs(true)}
                      variant="contained"
                    >
                      Continue
                    </Button>
                  </>
                ) : (
                  <NoCart clostFunction={handleClose} />
                )}
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Modal>
      {buyCLBs ? (
        <BuyCLBConfimation
          open={buyCLBs}
          setOpen={setBuyCLBs}
          CLBs={selectedCLBs}
          onClearCart={handleClearCart}
        />
      ) : null}
    </>
  );
}
