import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FacebookIcon from "@mui/icons-material/Facebook";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LanguageIcon from "@mui/icons-material/Language";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import { changeCLBsFavStatusAPI } from "../../redux/favorite/changeCLBsFavStatus";
import { getSingleCollection } from "../../service/collection";
import Style from "../../styles/singleCollection.module.scss";
import DividerUI from "../../ui/DividerUI";
import DiscodImg from "../../assets/png/discod.png";
import LoaderUI from "../../ui/LoaderUI";
import { Empty, CountdownTimer } from "../../helper/component";

const SingleCollection = () => {
  const [loading, setLoading] = useState(true);
  const changeCLBsFavStatusSelector = useSelector(
    (state) => state?.changeCLBsFavStatus
  );
  const { message, error } = changeCLBsFavStatusSelector;

  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result: userResult } = getCurrentUserSelector;

  console.log("userResult", userResult);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [nft, setNft] = useState();
  const [details, setDetails] = useState();
  const { id } = useParams();

  const getSingle = async (id) => {
    getSingleCollection(id).then(async (res) => {
      console.log("collectionResult=>2", res.data.result);
      await setNft(res?.data?.result[0]?.clbs);
      await setDetails(res?.data?.result[0]);
      setLoading(false);
    });
  };

  const handleFavirote = async (fav, clbId) => {
    await dispatch(changeCLBsFavStatusAPI({ isFavorite: fav, clbId }));
  };

  useEffect(() => {
    getSingle(id);
  }, [id]);

  console.log("details", details, userResult);

  return (
    <>
      <Box className={Style.main}>
        <Header />
        {loading ? (
          <LoaderUI />
        ) : (
          <Container container sx={{ mt: 4 }}>
            <Card className={Style.transCard}>
              {details?.cover && details?.cover?.length ? (
                <img
                  src={details?.cover}
                  alt="cover Image"
                  width={"100%"}
                  height={"300px"}
                />
              ) : (
                <img
                  src="https://jkmsfa.s3.ap-south-1.amazonaws.com/public/1706514662395-0.9025198530011946.png"
                  alt="No cover available"
                  width={"100%"}
                  height={"300px"}
                />
              )}
              <Box sx={{ mx: 4 }}>
                <Avatar
                  src={details?.picture}
                  variant="rounded"
                  sx={{
                    mt: -15,
                    ml: 2,
                    width: 200,
                    height: 200,
                    border: "1px solid #35ABAE",
                  }}
                />
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {details?.url && (
                    <Link to={details?.url}>
                      <LanguageIcon sx={{ color: "#fff" }} />
                    </Link>
                  )}
                </Stack>

                <Stack mt={1}>
                  <Stack direction="row" pt={1}>
                    <Typography sx={{ ml: 2, width: "10%" }}>Name</Typography>
                    <Typography sx={{ width: "90%" }} color="primary">
                      {details?.name}
                    </Typography>
                  </Stack>
                  <Stack direction="row" pt={1}>
                    <Typography sx={{ ml: 2, width: "10%" }}>By</Typography>
                    <Typography sx={{ width: "90%" }} color="primary">
                      {details?.userName}
                    </Typography>
                  </Stack>
                  <Stack direction="row" pt={1}>
                    <Typography sx={{ ml: 2, width: "10%" }}>
                      Created
                    </Typography>
                    <Typography sx={{ width: "90%" }} color="primary">
                      {moment(details?.created_at).format("DD-MMM-YYYY") ||
                        "--"}
                    </Typography>
                  </Stack>
                  <Stack direction="row" pt={1}>
                    <Typography sx={{ ml: 2, width: "10%" }}>
                      Description
                    </Typography>
                    <Typography sx={{ width: "90%" }} color="primary">
                      {details?.description}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              <DividerUI />
              <Grid container rowGap={4} spacing={3} sx={{ p: 2 }}>
                {nft?.map((item, idx) => {
                  // console.log("==>2", item);

                  return (
                    <Grid item lg={3} md={3} sm={6} xs={12} key={idx}>
                      <Paper
                        sx={{
                          bgcolor: "#000",
                          color: "#fff",
                          p: 4,
                          borderRadius: 4,
                        }}
                      >
                        {/* <Stack alignItems="flex-end"> */}
                        {/* {item?.favorites && item?.favorites != undefined ? (
                          <IconButton
                            onClick={() => handleFavirote(false, item?._id)}
                            className="likeBtn"
                          >
                            <FavoriteIcon sx={{ color: "primary.main" }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => handleFavirote(true, item?._id)}
                            className="likeBtn"
                          >
                            <FavoriteBorderIcon
                              sx={{ color: "primary.main" }}
                            />
                          </IconButton>
                        )} */}
                        {/* </Stack> */}
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          sx={{ px: 2, zIndex: 100 }}
                          pb={4}
                        >
                          <CountdownTimer endDate={item.endDate} />
                        </Stack>
                        <Box className="CLBBox">
                          {item?.type == "2d" && (
                            <img src={item?.files?.[0]} width={"100%"} />
                          )}
                          {item?.type == "3d" && (
                            <model-viewer
                              style={{ width: "100%", height: "220px" }}
                              src={item?.files?.[0]}
                              ar-modes="webxr scene-viewer quick-look"
                              camera-controls
                              poster="poster.webp"
                              shadow-intensity="1"
                              disable
                            ></model-viewer>
                          )}
                        </Box>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ mb: 4 }}
                          alignItems="center"
                        >
                          <Typography variant="p">{item?.title}</Typography>
                          <Typography variant="h6" className={Style?.price}>
                            ₹ {item?.cost?.inr}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          sx={{ mt: -4 }}
                        >
                          <Button className="gradBtn" variant="contained">
                            <Box>
                              <Typography
                                variant="subtitle2"
                                sx={{ color: "#999999" }}
                              >
                                Higest Bid
                              </Typography>
                              <Typography variant="p">
                                ₹ {item?.cost?.inr}
                              </Typography>
                            </Box>
                          </Button>
                          {item?.isBidAble &&
                          item.endDate >= new Date().toISOString() ? (
                            <Button
                              className={`${Style?.startBidBtn} gradBtn`}
                              variant="contained"
                              onClick={() => navigate(`/explore/${item._id}`)}
                            >
                              Start Bid
                            </Button>
                          ) : (
                            <Button
                              className={`${Style?.startPurchaseBtn} gradBtn`}
                              variant="contained"
                              // onClick={() => navigate(`/explore/${item._id}`)}
                              style={{ color: "#f50057", boxShadow: "none" }}
                              disabled
                            >
                              Ended
                            </Button>
                          )}
                        </Stack>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </Card>
          </Container>
        )}
      </Box>
    </>
  );
};
export default SingleCollection;
