import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Box, Button, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import UserNotLoggedIn from '../../common/UserNotLoggedIn';
import { changeCLBsFavStatusAPI } from '../../redux/favorite/changeCLBsFavStatus';
import { getCollectedCLBsAPI } from '../../redux/user/getCollectedCLBs';
import Style from "../../styles/topBid.module.scss";
import LoaderUI from '../../ui/LoaderUI';
import Snack from '../../ui/Snack';



const CollectedCLBs = () => {
    const getCollectedCLBsSelector = useSelector(state => state?.getCollectedCLBs)
    const { result, loading } = getCollectedCLBsSelector

    const getCurrentUserSelector = useSelector(state => state?.getCurrentUser)
    const { error: userError } = getCurrentUserSelector

    const changeCLBsFavStatusSelector = useSelector(state => state?.changeCLBsFavStatus)
    const { message, error } = changeCLBsFavStatusSelector

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate();


    const [CLBFilter, setCLBFilter] = useState("All")
    const [userNotLoggedIn, setUserNotLoggedIn] = useState(false)

    const handleFavirote = async (fav, clbId) => {
        if (userError) {
            return setUserNotLoggedIn(true)
        }
        await dispatch(changeCLBsFavStatusAPI({ isFavorite: fav, clbId }))
        await dispatch(getCollectedCLBsAPI({ ownerId: id, filter: CLBFilter }))

    }

    const handleFilterCollectCLBs = (filter) => {
        setCLBFilter(filter)
        dispatch(getCollectedCLBsAPI({ ownerId: id, filter }))
    }


    useEffect(() => {
        dispatch(getCollectedCLBsAPI({ ownerId: id, filter: CLBFilter }))
    }, []);

    return (
        <>
            <Stack direction='row' spacing={2} sx={{ p: 2 }}>
                <Button onClick={() => handleFilterCollectCLBs('All')} className={CLBFilter == 'All' ? 'roundBtn' : 'roundBtnInActive'} variant='contained'>All</Button>
                <Button onClick={() => handleFilterCollectCLBs('Bought')} className={CLBFilter == 'Bought' ? 'roundBtn' : 'roundBtnInActive'} variant='contained'>Bought</Button>
                <Button onClick={() => handleFilterCollectCLBs('BidPlaced')} className={CLBFilter == 'BidPlaced' ? 'roundBtn' : 'roundBtnInActive'} variant='contained'>Bids Placed</Button>
            </Stack >
            {loading
                ? <LoaderUI />
                : <Grid container spacing={3} sx={{ my: 4 }}>
                    {result?.map((item, idx) => {
                        return (
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <Paper className="SingelCLBBox">
                                    <Stack alignItems='flex-end'>
                                        {item?.favorites
                                            && item?.favorites != undefined
                                            ? <IconButton onClick={() => handleFavirote(false, item?._id)} className='likeBtn'><FavoriteIcon sx={{ color: 'primary.main' }} /></IconButton>
                                            : <IconButton onClick={() => handleFavirote(true, item?._id)} className='likeBtn'><FavoriteBorderIcon sx={{ color: 'primary.main' }} /></IconButton>
                                        }
                                    </Stack>
                                    <Box className="CLBBox">
                                        {item?.type == "2d" && <img src={item?.files?.[0]} width={'100%'} />}
                                        {item?.type == "3d"
                                            && <model-viewer
                                                style={{ width: '100%', height: 250 }}
                                                src={item?.files?.[0]}
                                                ar-modes="webxr scene-viewer quick-look"
                                                camera-controls
                                                poster="poster.webp"
                                                shadow-intensity="1"
                                                disable
                                            ></model-viewer>
                                        }
                                    </Box>
                                    <Stack sx={{ p: 2 }}>
                                        <Stack direction='row' justifyContent='space-between' sx={{ mt: 2 }} alignItems='center'>
                                            <Typography variant='p'>{item?.title}</Typography>
                                            <Typography variant='h6' className={Style?.price}>₹ {item?.cost?.inr}</Typography>
                                        </Stack>
                                        <Stack direction='row' justifyContent='space-between' >
                                            <Button className='gradBtn' variant='contained'>
                                                <Box>
                                                    <Typography variant='subtitle2' sx={{ color: '#999999' }}>Higest Bid</Typography>
                                                    <Typography variant='p'>₹ {item?.cost?.inr}</Typography>
                                                </Box>
                                            </Button>
                                            <Stack direction='row' spacing={2}>

                                                {item?.stage == "Purchased"
                                                    ? <Button className="gradBtn" variant="contained" onClick={() => navigate(`/sell/${item?._id}`)}>Sell</Button>
                                                    : <Button className="gradBtn" variant="contained" onClick={() => navigate(`/explore/${item?._id}`)}>See</Button>
                                                }

                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Paper >
                            </Grid>
                        )
                    })}
                </Grid >
            }
            {userNotLoggedIn ? <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} /> : null}
        </>

    );
};

export default CollectedCLBs;
