import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { default as React, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import LogoImg from "../../assets/png/logoImg1.png";
import SideImg from "../../assets/png/signInImg1.png";
import { resetPasswordAPI } from "../../redux/auth/resetPassword";
import Style from "../../styles/auth.module.scss";
import { OutlinedInputUI } from "../../theme/muiTheme";
import Snack from "../../ui/Snack";

const schema = yup
  .object({
    password: yup.string().required("Password required field"),
    comfimePassword: yup.string().required("Confirm password required field"),
  })
  .required();

export default function NewPassword() {
  const resetPasswordSelector = useSelector((state) => state?.resetPassword);
  const { message, error } = resetPasswordSelector;

  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordShow, setPasswordShow] = useState(false);
  const [confpasswordShow, setConfPasswordShow] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
  } = useForm({ resolver: yupResolver(schema) });

  const handleChangePassword = async (data) => {
    if (watch("password") != watch("comfimePassword")) {
      return setError("comfimePassword", {
        type: "custom",
        message: "passoword not match!",
      });
    }
    const passwordChange = await dispatch(resetPasswordAPI({ ...data, token }));

    if (!passwordChange?.payload?.error) {
      navigate("/signUp");
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          lg={4}
          md={4}
          sm={5}
          xs={12}
          className={Style?.signUp}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            height: "90vh",
            width: "90vw",
          }}
        >
          <Stack sx={{ p: 4, width: "100%" }}>
            <Box sx={{ width: 150 }}>
              <Link to="/">
                {" "}
                <img src={LogoImg} alt="Eduthum" width={"100%"} />
              </Link>
            </Box>
            <form onSubmit={handleSubmit(handleChangePassword)}>
              <Stack spacing={2} sx={{ mt: 5 }}>
                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>Password</FormLabel>
                  <OutlinedInputUI
                    placeholder="Enter your password"
                    inputProps={{
                      style: { color: "#000", borderRadius: "8px" },
                    }}
                    type={!passwordShow ? "password" : "text"}
                    endAdornment={
                      !passwordShow ? (
                        <IconButton onClick={() => setPasswordShow(true)}>
                          <VisibilityOffIcon color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setPasswordShow(false)}>
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      )
                    }
                    {...register("password")}
                  />
                  <FormHelperText error>
                    {errors?.password?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>
                    Confirm Password
                  </FormLabel>
                  <OutlinedInputUI
                    placeholder="Confirm your password"
                    inputProps={{ style: { color: "#000" } }}
                    type={!confpasswordShow ? "password" : "text"}
                    endAdornment={
                      !confpasswordShow ? (
                        <IconButton onClick={() => setConfPasswordShow(true)}>
                          <VisibilityOffIcon color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setConfPasswordShow(false)}>
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      )
                    }
                    {...register("comfimePassword")}
                  />
                  <FormHelperText error>
                    {errors?.comfimePassword?.message}
                  </FormHelperText>
                </FormControl>

                <Button variant="contained" type="submit" size="large">
                  Change Password
                </Button>
              </Stack>
            </form>
          </Stack>
        </Grid>
        <Grid item lg={8} md={8} sm={6} xs={12} className={Style?.bgImage}>
          <Box className={Style?.sideImg}>
            <img src={SideImg} alt="background image" width={"100%"} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
