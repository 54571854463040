import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import { getUserCollection } from "../../service/collection";
import Style from "../../styles/collection.module.scss";
import DividerUI from "../../ui/DividerUI";
import { useDispatch, useSelector } from "react-redux";
import { getUserCollectionAPI } from "../../redux/collection/getUserCollection";
import LoaderUI from "../../ui/LoaderUI";

const Coll = () => {
  const getUserCollectionSelector = useSelector(
    (state) => state?.getUserCollection
  );
  const { result, loading } = getUserCollectionSelector;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserCollectionAPI());
  }, []);

  return (
    <Box className={Style.main}>
      <Header />
      <Stack spacing={2} alignItems="center" sx={{ mx: 2 }}>
        <Typography variant="h4">My Collections</Typography>
        <Typography>
          Create, curate, and manage collections of unique NFTs to share and
          sell.
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/collection/create")}
        >
          Create a Collection
        </Button>
      </Stack>
      <DividerUI />
      <Container>
        <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
          {loading ? (
            <LoaderUI />
          ) : (
            result?.map((item) => {
              return (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={12}
                  onClick={() => navigate(`/collection/${item._id}`)}
                >
                  <Card sx={{ bgcolor: "#050505", p: 0 }}>
                    <Box sx={{ width: "100%", height: 100 }}>
                      <img
                        src={item.cover}
                        width={"100%"}
                        height={"100%"}
                        alt="cover image"
                      />
                    </Box>
                    <Stack
                      sx={{ color: "#fff", mt: -5, p: 2 }}
                      alignItems="center"
                      spacing={2}
                    >
                      <Avatar
                        src={item.picture}
                        alt="picture"
                        sx={{ width: 70, height: 70 }}
                      />
                      <Typography variant="h6">{item?.name}</Typography>
                      {/* <Typography variant="p">
                        Rs. {item.price ? item.price : 0}
                      </Typography> */}
                    </Stack>
                  </Card>
                </Grid>
              );
            })
          )}
        </Grid>
      </Container>
    </Box>
  );
};
export default Coll;
