import { yupResolver } from "@hookform/resolvers/yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaFacebookF, FaApple } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import LogoImg from "../../assets/png/logoImg1.png";
import SideImg from "../../assets/png/signInImg1.png";
import {
  signInWithFacebookPopup,
  signInWithGooglePopup,
} from "../../config/firebase";
import { singInAPI } from "../../redux/auth/singIn";
import Style from "../../styles/auth.module.scss";
import { OutlinedInputUI, TextFieldUI } from "../../theme/muiTheme";
import Snack from "../../ui/Snack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import parseGooglePlace from "parse-google-place";

const schema = yup
  .object({
    name: yup
      .string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .trim()
      .required("Name required field"),
    email: yup.string().trim().required("Email required field"),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .trim()
      .required("Password required field"),
    comfimePassword: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .trim()
      .required("Confirm password required field"),
  })
  .required();

export default function SignUp() {
  const singInSelector = useSelector((state) => state?.singIn);
  let { message, error } = singInSelector;
  const navigate = useNavigate();
  const singUpSelector = useSelector((state) => state?.singUp);
  const { message: loginMsg, error: loginError } = singUpSelector;

  const dispatch = useDispatch();

  const {
    control,
    register,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [location, setLocation] = useState(null);
  const [country, setCountry] = useState("India");
  const [passwordShow, setPasswordShow] = useState(false);
  const [confpasswordShow, setConfPasswordShow] = useState(false);

  const handleSignUp = async (data) => {
    if (watch("password") !== watch("comfimePassword")) {
      return setError("comfimePassword", {
        type: "custom",
        message: "Passwords do not match!",
      });
    }
    const signIn = await dispatch(
      singInAPI({ type: "Individual", ...data, location, country })
    ).then((res) => {
      // console.log(res);
      if (!res?.payload.error) {
        // window.location.href = "/signIn";
        navigate("/signIn");
      }
    });
    // console.log("got signIn", signIn);
    // alert("asdf");
    // Navigate("signUp");
  };

  const hanldeSignInWithGoogle = async () => {
    const response = await signInWithGooglePopup();
    const { user } = response;
    const passKey = uuidv4();
    const password = `Edu${user?.displayName?.split(" ")?.[0]}${passKey}`;
    console.log(user);
    await dispatch(
      singInAPI({
        type: "Individual",
        email: user?.email,
        name: user?.displayName,
        password,
        location,
        country,
        provider: "google",
      })
    ).then((res) => {
      console.log(res);

      if (!res?.payload.error) {
        window.location.href = "/signIn";
      }
    });
  };

  const hanldeSignInWithFacebook = async () => {
    const response = await signInWithFacebookPopup();
    const { user } = response;
    const passKey = uuidv4();
    const password = `Edu${user?.displayName?.split(" ")?.[0]}${passKey}`;
    await dispatch(
      singInAPI({
        type: "Individual",
        email: user?.email,
        name: user?.name,
        name: user?.displayName,
        password,
        location,
        country,
        provider: "facebook",
      })
    ).then((res) => {
      console.log(res);
      setTimeout(() => {}, 2000);
      if (!res?.payload.error) {
        navigate("/signIn");
      }
    });
  };

  // const handleSignInWithApple = async () => {
  // const response = await signInWithApplePopup();
  // const { user } = response;
  // const signUp = await dispatch(singUpAPI({ provider: "apple", email: user?.email }));

  // if (!signUp?.payload?.error) {
  //   localStorage.setItem("accessToken", signUp?.payload?.result?.accessToken);
  //   navigate("/");
  // }
  //   return null;
  // };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("position", position);

        const { latitude, longitude } = position.coords;
        const API_KEY = "YOUR_API_KEY";
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyC1Cz13aBYAbBYJL0oABZ8KZnd7imiWwA4`;
        axios.get(url).then((res) => {
          const address = parseGooglePlace(res.data.results[0]);
          console.log("address", address);
          setLocation({ latitude, longitude });
          setCountry(address?.countryLong);
        });
      });
    }
  }, []);

  return (
    <>
      <Grid container className={Style.login}>
        <Grid item lg={4} md={4} sm={5} xs={12} className={Style?.signUp}>
          <Stack sx={{ width: "100%" }}>
            <Box sx={{ width: 150 }}>
              <Link to="/">
                {" "}
                <img src={LogoImg} alt="Eduthum" />
              </Link>
            </Box>
            <Typography variant="h4" sx={{ mt: "1rem", color: "#fff" }}>
              Welcome !
            </Typography>
            <Typography variant="h3" className={Style.signInText}>
              Sign up
            </Typography>
            <form onSubmit={handleSubmit(handleSignUp)}>
              <Stack spacing={2} sx={{ mt: 5 }}>
                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>
                    Name<sup>*</sup>
                  </FormLabel>
                  <OutlinedInputUI
                    placeholder="Enter your name"
                    pattern="[A-Za-z]+"
                    inputProps={{ style: { color: "#000" } }}
                    type="name"
                    {...register("name")}
                  />
                  <FormHelperText error>{errors?.name?.message}</FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>
                    Email<sup>*</sup>
                  </FormLabel>
                  <OutlinedInputUI
                    placeholder="Enter your email"
                    inputProps={{ style: { color: "#000" } }}
                    type="email"
                    {...register("email")}
                  />
                  <FormHelperText error>
                    {errors?.email?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>
                    Password<sup>*</sup>
                  </FormLabel>
                  <OutlinedInputUI
                    placeholder="Enter your password"
                    inputProps={{ style: { color: "#000" } }}
                    type={!passwordShow ? "password" : "text"}
                    endAdornment={
                      !passwordShow ? (
                        <IconButton onClick={() => setPasswordShow(true)}>
                          <VisibilityOffIcon color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setPasswordShow(false)}>
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      )
                    }
                    {...register("password")}
                  />
                  <FormHelperText error>
                    {errors?.password?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl>
                  <FormLabel sx={{ color: "#35abaf" }}>
                    Confirm Password<sup>*</sup>
                  </FormLabel>
                  <OutlinedInputUI
                    placeholder="Confirm your password"
                    inputProps={{ style: { color: "#000" } }}
                    type={!confpasswordShow ? "password" : "text"}
                    endAdornment={
                      !confpasswordShow ? (
                        <IconButton onClick={() => setConfPasswordShow(true)}>
                          <VisibilityOffIcon color="primary" />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => setConfPasswordShow(false)}>
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      )
                    }
                    {...register("comfimePassword")}
                  />
                  <FormHelperText error>
                    {errors?.comfimePassword?.message}
                  </FormHelperText>
                </FormControl>

                <Button variant="contained" type="submit" size="large">
                  Register
                </Button>
              </Stack>
            </form>

            <Stack spacing={1} alignItems="center" sx={{ mt: 2 }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography> Already have an Account ?</Typography>
                <Link to="/signIn">
                  <Typography sx={{ color: "#35abaf" }}>Sign In</Typography>
                </Link>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography> Sign Up as an Organization ?</Typography>
                <Link to="/orgSignUp">
                  <Typography sx={{ color: "#35abaf" }}>Sign Up</Typography>
                </Link>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-around"
                sx={{ pt: 2, width: "60%" }}
              >
                <FcGoogle
                  onClick={hanldeSignInWithGoogle}
                  className={Style.direct}
                />
                <FaFacebookF
                  onClick={hanldeSignInWithFacebook}
                  className={Style.direct}
                  style={{ color: "#3C5A9A" }}
                />
                {/* <FaApple
                  onClick={handleSignInWithApple}
                  className={Style.direct}
                  style={{ color: "#35abaf"}}
                /> */}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={8} md={8} sm={6} xs={12} className={Style?.bgImage}>
          <Box className={Style?.sideImg}>
            <img src={SideImg} alt="backgroundImage" width={"100%"} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
