import React, { useState, useEffect } from "react";
import Style from "./faq.module.css";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Header from "../../header/Header";
import { Box } from "@mui/material";

const Faqs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const [search, setSearch] = useState();
  const questions = [
    {
      head: "What are 3D Collectible Learning Blocks?",
      para: `3D CLB stands for Collectible Learning Blocks. These are 3D models that are designed specifically for educational purposes.They can be used to teach a wide 
            variety of subjects, such as science, maths, geography, and history. 3D CLBs are 
            interactive and can be manipulated by students, allowing them to learn through 
            hands- on exploration.They are often used in conjunction with virtual reality or 
            augmented reality technologies to create immersive learning experiences.The goal 
            of 3D CLBs is to make learning more engaging and effective by providing students
            with a fun and interactive way to learn.`,
    },
    {
      head: "How do CLB work and how to collect them?",
      para: `CLBs can be used with augmented reality (AR) and virtual reality (VR) technologies 
        to provide students with a hands-on, interactive learning experience.
        To use CLBs, you will need a Laptop/PC or compatible AR/VR device, such as a 
        smartphone or headset, and eduthum app or software. Once you have the 
        necessary equipment, you can use the CLB to access the whole eduthum 
        metaverse. This will allow you to explore the 3D model and interact with it, gaining a 
        deeper understanding of the educational concept it represents.
        To collect the CLB, you need to purchase the subscription plan with life time free 
        access to the current and upcoming content.`,
    },
    {
      head: "What is AR/VR immersive learning?",
      para: `AR/VR immersive learning is a form of education that uses augmented reality and 
            virtual reality technologies to create interactive and immersive learning experiences.`,
    },
    {
      head: "What are the benefits of AR/VR immersive learning?",
      para: `AR/VR immersive learning can increase student engagement, improve retention of 
            information, and provide a more interactive and dynamic learning experience. It also 
            allows for the exploration of subjects in a safe and controlled environment.`,
    },
    {
      head: "What are some examples of AR/VR immersive learning experiences?",
      para: `AR/VR immersive learning experiences can include virtual field trips, simulations, 
            and interactive exercises that allow students to explore and interact with 3D objects 
            and environments.`,
    },
    {
      head: "What equipment is needed for AR/VR immersive learning?",
      para: `AR/VR immersive learning can be experienced using a variety of devices, including 
            VR headsets, smartphones, tablets, and desktop computers. The necessary 
            equipment will depend on the specific learning experience.`,
    },
    {
      head: "What subjects are suitable for AR/VR immersive learning?",
      para: `AR/VR immersive learning can be applied to a wide range of subjects, including 
            science, history, geography, and art. It is particularly useful for subjects that involve 
            complex or abstract concepts that are difficult to visualise or explain with traditional 
            teaching methods.`,
    },
    {
      head: "How is AR/VR immersive learning integrated into traditional teaching methods?",
      para: `AR/VR immersive learning can be integrated into traditional teaching methods by 
            supplementing classroom instruction with immersive learning experiences or by 
            using AR/VR technologies to create interactive and engaging learning activities.`,
    },
    {
      head: "How can educators create AR/VR immersive learning experiences?",
      para: `Educators can create AR/VR immersive learning experiences using specialised 
            software or by partnering with companies that specialise in creating immersive 
            learning content`,
    },
    {
      head: "Is AR/VR immersive learning safe?",
      para: `AR/VR immersive learning is generally safe, as it allows students to learn in a 
            controlled and safe environment. However, it is important to ensure that students are
            using the equipment properly and that they are supervised during the learning 
            experience.`,
    },
    {
      head: "How can AR/VR immersive learning be used in the classroom?",
      para: `AR/VR immersive learning can be used in the classroom to supplement traditional 
            teaching methods, to provide an interactive and engaging learning experience for 
            students, and to enhance learning outcomes. It can be used to teach complex 
            concepts, to simulate real-world experiences, and to provide hands-on learning 
            opportunities.`,
    },
  ];
  console.log("search", search);
  return (
    <Box className={Style.main}>
      <Header />
      <div className={Style.container}>
        <div className={Style.contained}>
          <h1>How can we help you ?</h1>
          <div className={Style.searchbar}>
            <p>
              <SearchRoundedIcon style={{ color: "white" }} />
            </p>
            <input
              type="text"
              name=""
              id=""
              placeholder="Search articles here"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <h1 style={{ marginTop: "10px", marginBottom: "10px" }}>Popular Search</h1>
          <div className={Style.faqs}>
            {/* <div className= {Style.faq}>
                            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. </span>
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Adipisci suscipit non ea cumque dolores quidem labore. Voluptas, sit et delectus veniam aliquam eaque sint? Placeat molestias quam fuga autem libero.</p>
                        </div> */}
            {questions &&
              questions
                .filter((e) => {
                  if (search) {
                    return e.head.toLowerCase().includes(search.toLowerCase());
                  }
                  return e;
                })
                .map((el, i) => {
                  return (
                    <div className={Style.faq} key={i}>
                      <span>{el.head} </span>
                      <p>{el.para}</p>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </Box>
  );
};
export default Faqs;
