import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack,
  Popover,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { default as React, useState } from "react";
import { BsCartDash, BsWallet2 } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { MdNotificationsActive, MdOutlineGridOn } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DpImage from "../../assets/jpg/user-profile.jpg";
import LoaderUI from "../../ui/LoaderUI";
import { PeraWalletConnect } from "@perawallet/connect";
import { useSDK } from "@metamask/sdk-react";
import toast from "react-hot-toast";
import HttpService from "../../config/authAxios";

export default function NavigationMenu({
  setCart,
  setNotification,
  open,
  setOpen,
  anchorEl,
}) {
  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result, loading } = getCurrentUserSelector;
  const { sdk, connected, connecting, provider, chainId } = useSDK();
  const navigate = useNavigate();
  const [account, setAccount] = useState("");

  const handleClose = () => setOpen(false);
  const handleLogout = () => {
    localStorage.clear();
    // navigate("/signIn")
    window.location.href = "/signIn";
  };
  const handleViewProfile = () => {
    navigate(`/profile/${result?._id}`);
    setOpen(false);
  };

  const connect = async () => {
    try {
      const accounts = await sdk?.connect();
      // console.log(accounts);
      setAccount(accounts?.[0]);
      try {
        const { data } = await HttpService.put("/users/update-metamask", {
          metamaskAddress: accounts?.[0],
        });
        // console.log(data);
      } catch (err) {
        console.warn(`failed to connect..`, err);
        toast.error("Wallet Connection Failed", {
          duration: 5000,
          position: "bottom-left",
        });
      }
    } catch (err) {
      console.warn(`failed to connect..`, err);
      toast.error("Wallet Connection Failed", {
        duration: 5000,
        position: "bottom-left",
      });
    }
  };

  const handleParaPay = async () => {
    const peraWallet = new PeraWalletConnect({ chainId: "416001" });
    const walleteAddress = await peraWallet.connect();
    console.log("walleteAddress", walleteAddress);
  };

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <Box sx={{ p: 3 }}>
        {loading ? (
          <LoaderUI />
        ) : (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Avatar
                src={result?.picture || DpImage}
                alt="user image"
                sx={{ width: 70, height: 70, border: "1px solid #35ABAE" }}
              />
              <Stack>
                <Typography variant="h5">{result?.name}</Typography>
                <Button
                  sx={{ color: "primary.main" }}
                  onClick={handleViewProfile}
                >
                  View profile
                </Button>
              </Stack>
            </Stack>

            <List dense disablePadding component="div">
              <Divider color="#fff" sx={{ my: 2 }} />
              <ListItem
                disablePadding
                dense
                onClick={() => {
                  navigate("/profile/edit");
                }}
              >
                <ListItemButton onClick={handleClose}>
                  <ListItemIcon sx={{ color: "#fff" }}>
                    <CgProfile size={20} />
                  </ListItemIcon>
                  <ListItemText primary="Edit profile" />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                dense
                onClick={() => {
                  setCart(true);
                }}
              >
                <ListItemButton onClick={handleClose}>
                  <ListItemIcon sx={{ color: "#fff" }}>
                    <BsCartDash size={20} />
                  </ListItemIcon>
                  <ListItemText primary="Cart" />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                dense
                onClick={() => {
                  navigate("/collection");
                }}
              >
                <ListItemButton onClick={handleClose}>
                  <ListItemIcon sx={{ color: "#fff" }}>
                    <MdOutlineGridOn size={20} />
                  </ListItemIcon>
                  <ListItemText primary="My Collections" />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                dense
                onClick={() => {
                  navigate("/orders");
                }}
              >
                <ListItemButton onClick={handleClose}>
                  <ListItemIcon sx={{ color: "#fff" }}>
                    <BsWallet2 size={20} />
                  </ListItemIcon>
                  <ListItemText primary="My Orders" />
                </ListItemButton>
              </ListItem>

              {result?.type === "Organization" && (
                <ListItem
                  disablePadding
                  dense
                  onClick={() => {
                    navigate("/ticket/new");
                  }}
                >
                  <ListItemButton onClick={handleClose}>
                    <ListItemIcon sx={{ color: "#fff" }}>
                      <BsWallet2 size={20} />
                    </ListItemIcon>
                    <ListItemText primary="Raise a Ticket" />
                  </ListItemButton>
                </ListItem>
              )}
              <ListItem>
                <ListItemButton onClick={connect}>
                  <ListItemIcon sx={{ color: "#fff" }}>
                    <BsWallet2 size={20} />
                  </ListItemIcon>
                  <ListItemText primary="Wallet" />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                dense
                onClick={() => setNotification(true)}
              >
                <ListItemButton onClick={handleClose}>
                  <ListItemIcon sx={{ color: "#fff" }}>
                    {/* notification logic  */}

                    {!+result ? (
                      <MdNotificationsActive size={20} color="white" />
                    ) : (
                      <MdNotificationsActive size={20} color="red" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Notifications" />
                </ListItemButton>
              </ListItem>
              <Popover
                open={Boolean(popoverAnchorEl)}
                anchorEl={popoverAnchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box sx={{ p: 2, backgroundColor: "red", color: "#fff" }}>
                  <Typography>
                    You have {result} unseen notifications!
                  </Typography>
                </Box>
              </Popover>

              <ListItem>
                <Button fullWidth onClick={handleLogout} variant="contained">
                  Logout
                </Button>
              </ListItem>
            </List>
          </>
        )}
      </Box>
    </Menu>
  );
}
