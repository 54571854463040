import React, {useEffect} from "react";
import styles from "./terms.module.css";
import Footer from "../../../common/footer";
import Header from "../../header/Header";

const Terms = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  return (
    <div>
    <Header/>
    <div
      style={{
        background: `url(/images/background/Form.png) 100% 100% `,
        backgroundPosition: "center",
        backgroundSize: "100vw ",
      }}
    >
      <div className={styles.container}>
        <div className={styles.contained}><br/>
          <h1>Terms & Conditions</h1>
          <hr /><br/><br/>
          <p>
            Following Terms & Conditions regarding (a) use of our website
            eduthum.io (“Website”), Use of our applications (“Application”) or
            any products or services in connection with the Application/,
            Website/products (“Services”) or (b) any modes of registrations or
            usage of products, including through SD cards, tablets VR headset or
            other storage/transmitting device are between Eduthum Ventures
            Private Limited (“Company/We/Us/Our”) and it’s users
            (“User/You/Your”). These Terms are prepared as an electronic record
            in accordance with the provisions of the Information Technology Act,
            2000 and the Information Technology (Intermediaries guidelines)
            Rules, 2011 thereunder, as rectified from time to time.
          </p>
          <p>
            Please read Terms and the privacy policy of the Company (“Privacy
            Policy”) carefully, which is applicable for registration with us,
            the use of the Application, Website, Services, and products before
            using the Application, Website, Services, or products. In case of
            any discrepancy between the Terms and any other policies with
            respect to the Application or Website or Services or products, the
            provisions of the Terms will be considered as final. Your
            use/access/browsing of Application or Website or the Services or
            products or registration (with or without payment/with or without a
            subscription) is considered that you have accepted Terms and Privacy
            Policy, Your agreement to be legally bound by the same. If You do
            not agree with the Terms or the Privacy Policy please do not use
            Application or Website or avail the Services or products. Access to
            our Services/Application/products with registrations/subscription is
            non-transferable.
          </p>
          <ul>
            <li>
              <p>
                All information, content, material, trademarks, services marks,
                trade names, and trade secrets including but not limited to the
                software, text, images, graphics, video, script and audio,
                contained in the Application, Website, Services, and products
                are the proprietary property of the Company. It is not allowed
                to copy, download, reproduce, modify, republish, upload, post,
                transmit or distribute without obtaining prior written
                permission from the Company and nothing on this Application or
                Website or Services shall be or products is considered as a
                license of or any other right, interest or title to or in any of
                the intellectual property rights that belong to the company, to
                the User. You may own the medium on which the information,
                content, or materials is stored, but the Company shall at all
                times retain full and complete title to the information,
                content, or materials and all intellectual property rights
                applied by the Company on such medium. You are not permitted to
                use any trademarks, copyright, logos, service marks, and other
                intellectual property of any third party that connected or use
                in our Application, Website, Services, or products.
              </p>
            </li>
            <li>
              <p>
                Your use of our products, Website, Application, and Services is
                solely for Your personal and non-commercial use. Any use of the
                Application, Website, Services or products or their contents
                other than for personal purposes is prohibited. Personal and
                non- commercial use of this Application, Website, products,
                and/or our Services is be subjected to the following
                restrictions
              </p>
              <ol>
                <li>
                  <p>
                    It is prohibited to decompile, reverse engineer, or
                    disassemble the contents of the Application and/or our
                    Website and/or Services/ products or modify, copy,
                    distribute, transmit, display, perform, reproduce, publish,
                    license, create derivative works from, transfer, or sell any
                    information or software obtained from the Application and/or
                    our Website and/or Services/products, or remove any
                    copyright, trademark registration, or other proprietary
                    notices from the contents of the Application and/or our
                    Website and/or Services/products
                  </p>
                </li>
                <li>
                  <p>
                    You will not (a) use this application and/or our Website
                    and/or any of our product/s or Service/s for commercial
                    purposes or (b) advertise or sell Application or any
                    products, services (whether or not for profit) (c) solicit
                    others (including, without limitation, solicitations for
                    contributions or donations) (d) use any public forum for
                    commercial purposes of any kind (e) use Application and/or
                    Website/our products and Services in any way that is
                    unlawful, or harms the Company/any other person or entity as
                    determined by the Company.
                  </p>
                </li>
                <li>
                  <p>
                    No User is permitted to perform any of the following
                    prohibited activities while using our Services :
                  </p>
                  <ul>
                    <li>
                      <p>
                        Providing any content that is misleading, unlawful,
                        harmful, threatening, abusive, tortious, defamatory,
                        libelous, vulgar, obscene, child-pornographic, lewd,
                        lascivious, profane, invasive of another’s privacy,
                        hateful, or racially, ethnically or otherwise
                        objectionable.
                      </p>
                    </li>
                    <li>
                      <p>
                        Stalking, intimidating and/or harassing other and/or
                        inciting others to commit violence.{" "}
                      </p>
                    </li>
                    <li>
                      <p>
                        Transmitting material that encourages anyone to commit a
                        criminal offense, that results in civil liability or
                        breaches any relevant laws, regulations or code of
                        practice.{" "}
                      </p>
                    </li>
                    <li>
                      <p>
                        Interfering other person’s use or enjoyment of the
                        Application/Website/Services.{" "}
                      </p>
                    </li>
                    <li>
                      <p>
                        Making, transmitting or storing electronic copies of
                        materials protected by copyright without the permission
                        of the owner, committing any act that amounts to the
                        infringement of intellectual property or making
                        available any material that infringes any intellectual
                        property rights of anyone else.
                      </p>
                    </li>
                    <li>
                      <p>
                        Impersonate any person or entity, or falsely state or
                        otherwise misrepresent Your affiliation with a person or
                        entity;
                      </p>
                    </li>
                    <li>
                      <p>
                        Post, transmit or make available any material that
                        contains viruses, trojan horses, worms, spyware, time
                        bombs, cancelbots, or other computer programming
                        routines, code, files or programs that may harm the
                        Application/services, interests or rights of other users
                        or limit the functionality of any computer software,
                        hardware or telecommunications, or that may collect any
                        data or personal information about other Users.
                      </p>
                    </li>
                    <li>
                      <p>
                        Access or use Application/Website/Services/products in
                        any manner that could damage, disable, overburden or
                        impair any of servers or the networks connected to any
                        of the servers on which the application/Website is
                        hosted;
                      </p>
                    </li>
                    <li>
                      <p>
                        Intentionally or unintentionally interfere or disrupt
                        the Services or violate any applicable laws related to
                        the access to or use of the
                        Application/Website/Services/products, or violate any
                        requirements, procedures, policies or regulations of
                        networks connected to the
                        Application/Website/Services/products, or engage in any
                        activity that is prohibited by these Terms;
                      </p>
                    </li>
                    <li>
                      <p>
                        Disrupt, interfere or harm the security of
                        Application/Website/Services/products, materials,
                        systems resources, or gain unauthorized access to user
                        accounts, passwords, servers or networks connected to or
                        accessible through the
                        Application/Website/Services/products or any affiliated
                        or linked sites;
                      </p>
                    </li>
                    <li>
                      <p>
                        Interfere or prevent any user from using and enjoying
                        access to the Application/Website/ Services/products, or
                        other affiliated sites, or engage in disruptive attacks
                        such as a denial of service attack on the
                        Application/Website/Services/products;
                      </p>
                    </li>
                    <li>
                      <p>
                        Use deep-links, page-scrape, robot, spider or another
                        automatic device, program, algorithm or methodology, or
                        any similar or equivalent manual process, to increase
                        traffic to the Application/Website/Services/products, to
                        access, acquire, copy or monitor any portion of the
                        Application /Website/Services/products, or reproduce or
                        circumvent the navigational structure or presentation of
                        the Application, or any content, attempt to obtain any
                        content, documents or information through any means not
                        specifically made available through the Application/
                        Website/Services/products;
                      </p>
                    </li>
                    <li>
                      <p>Alter or modify any part of the Services;</p>
                    </li>
                    <li>
                      <p>
                        Use the Services for purposes that are not permitted by
                        (i) these Terms; and (ii) any applicable law, regulation
                        or generally accepted practices or guidelines in the
                        relevant jurisdiction;
                      </p>
                    </li>
                    <li>
                      <p>
                        Violate any of the terms specified under the Terms for
                        the use of the Application /Website/Services/products.
                      </p>
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
          </ul>
          <p>
            We have taken enough care while preparation of the Application /
            Website / Services / Products and contents therein, to offer the
            most current, correct, and clearly expressed information possible.
            However, errors may occur. In particular, but without limiting
            anything here, we are not responsible for any errors and accuracy of
            the information that may be contained in the Application. Any
            feedback from Users is most welcome to make the Application and
            contents thereof error-free and user friendly. The company also
            reserves the right and discretion to make any changes/corrections or
            withdraw/add contents at any time without notice. Neither the
            Company nor any third parties provide any warranty or guarantee for
            accuracy, timeliness, performance, completeness or suitability of
            the information and materials found or offered on
            Application/Website/Services/products for any particular purpose.
            You acknowledge that such information and materials may contain
            inaccuracies or errors and we expressly exclude liability for any
            such inaccuracies or errors to the fullest extent permitted by law.
          </p>
          <p>
            Our website may also include links to other websites to provide
            further information. We have no responsibility for the content of
            the linked website(s). You may not create a link to the Website from
            another website or document without the Company’s prior written
            consent. Such information is provided on an as-Is basis and we
            assume no liability for the accuracy, completeness, or use or
            non-obsolescence of such information.
          </p>
          <p>
            The contents of the Application/Services/products are developed on
            the concepts covered in the structured curriculum syllabus
            prescribed for students of various courses. The usage of the
            Application/Services/products is not endorsed as a substitution to
            the curriculum-based education provided by the educational
            institutions but is intended to supplement the same by explaining
            and presenting the concepts in a manner enabling easy understanding.
            The basic definitions and formulae of the subject matter would
            remain the same. The Company acknowledges that there are various
            means of delivering structured curriculum pedagogy and inclusion of
            methods in the Application/Services/products does not imply
            endorsement of any particular method nor exclusion imply
            disapproval. Subscription to the Application or usage of our
            Services/Website/products does not in any manner guarantee admission
            to any educational institutions or passing of any exams or
            achievement of any specified percentage of marks in any
            examinations.
          </p>
          <p>
            Some parts of the Services are interactive, and we encourage
            contributions by users, which may or may not be subject to editorial
            control prior to being posted. The Company reserves the right at its
            sole discretion to remove, review, edit, or delete any content.
          </p>
          <p>
            The Company may contact the User through SMS, email, Whatsapp and
            call, to give information about its products as well as
            notifications on various important updates and/or to seek permission
            for demonstration of its products. The User grants such permission
            to contact him/her through telephone, SMS, e-mail, Whatsapp and
            holds the company indemnified against any liabilities including
            financial penalties, damages, expenses in case the User’s mobile
            number is registered with Do not Call (DNC) database. By registering
            yourself, you agree to make your contact details available to our
            employees, associates, and partners so that you may be contacted for
            education information and promotions through telephone, SMS, email,
            Whatsapp, etc.
          </p>
          <p>
            While the company may, based on the User’s confirmation, provides
            the demonstration of its products at the location chosen by the
            User, the User acknowledges that he/she has not been induced by any
            statements or representations of any person with respect to the
            quality or conditions of the products and that User has relied
            solely on the investigations, examinations, and inspections as the
            User has chosen to make and that the Company has afforded the User
            the opportunity for full and complete investigations, examinations
            and inspections.
          </p>
          <p>
            After registration through any means whatsoever, the Company may
            contact You through the registered mobile number or e-mail or any
            other mobile number or contact number or email provided by You to
            enable effective provision of Services. The User expressly permits
            the Company to contact him/her and the student utilizing the
            Services, through the above-mentioned medium at any time after
            registration. Further, the Company shall have the right to monitor
            the download and usage of the Application/Services/products and the
            contents thereof by the User/student, to analyze such usage and
            discuss the same with the User/student to enable effective and
            efficient usage of the Services. The User expressly permits the
            Company to clear the doubts of the student using the
            Application/Services/online portal by answering the questions placed
            before it, providing study plans, informing of the progress,
            providing feedback, communicating with the student and mentoring the
            student through telephone or e-mail on the express consent of the
            legal guardian/parent of the User or through any other medium.
          </p>
          <p>
            While the Company has made efforts to train the personnel engaged in
            the sales and services relating to products to enable quality
            control, it makes no warranties or representations whatsoever
            regarding the quality and competence of such personnel and would not
            be responsible for any deviant behavior of any such personnel. Any
            feedback from Users relating to the same is most welcome and Company
            reserves the right and discretion to take any action in this regard.
          </p>
          <p>
            Access to certain elements of the Services including doubt
            clearance, mentoring services, etc may be subject to separate terms,
            conditions, and fair usage policy. The Company reserves the right to
            determine the criteria for the provision of various elements of
            Services to the different categories of Users based on its policies.
            Hence, subscription to the Application/products or registrations do
            not automatically entitle the User to any and all elements of
            Services provided by the Company and the Company shall be entitled
            to exercise its discretion while providing access to and determining
            continuity of certain elements of Services. We reserve the right to
            extend, cancel, discontinue, prematurely withdraw, or modify any of
            Our Services at Our discretion.
          </p>
          <p>
            The company may conduct competitions from time to time and you agree
            to allow the company to use the submissions by you including but not
            limited to videos, written content, craftwork, etc for promotional
            purposes. All rights will be reserved by the company for usage of
            such submissions by you. You additionally permit the company to use
            every kind of information submitted by you like photos, videos, etc.
            for promotional campaigns as it so pleases and you will cease to
            have any rights on the same once your submissions are made to the
            company.
          </p>
          <p>
            The Company’s products and/or Services, including the Application
            and content, are compatible only with certain
            devices/tablets/instruments/hardware. The Company shall not be
            obligated to provide compatible products and/or services for any
            instruments that are not recognized by the Company or those
            instruments that may be purchased from any third party which are not
            compatible with the Company’s products and Services. The company
            reserves the right to upgrade the table/ type of compatible devices
            as required from time to time.
          </p>
          <p>
            The Company shall have no responsibility for any loss or damage
            caused to the tablet or any other hardware and / or software and/or
            instrument, including loss of data or effect on the processing
            speed, resulting from your use of our products and Services.
          </p>
          <p>
            You have to specify the shipping address at the time of purchase.All
            product(s) shall be delivered directly to the address as specified.
            You cannot change the shipping address after the order is processed
            under any circumstances.In case of any change in the address, You
            need to specify the same to us in writing well in advance to the
            shipping date. Any inconsistencies in name or address will result in
            non-delivery of the product(s). (a) For return of product(s) damaged
            at the time of delivery, the shipping charges shall be borne by the
            Company. However, for return any of the product(s) for any other
            reasons, it shall be the responsibility of the User to arrange for
            the return of such cancelled product(s) and the shipping charges
            shall be borne by such User. (b)We request You not to accept any
            product package that seems to be tampered with, opened or damaged at
            the time of delivery. The products must be returned in the same
            condition as delivered by the Company. Any products returned showing
            signs of any use or damage in any manner shall not be accepted for
            return. (c)All requests for return of products have to be placed
            within 15 (fifteen) days from the date of delivery. Please note that
            no refunds shall be claimed or will be entertained post 15 (fifteen)
            days from the date of delivery. You acknowledge that the Company is
            not the manufacturer of the instrument/medium/hardware and hence,
            any defect relating to the same shall be reported to the
            manufacturer whose details shall be specified on the packaging and
            the Company shall not be in any manner responsible for the same. The
            Company does not provide any guarantee or warranty relating to the
            instrument/medium/hardware. You are responsible to maintain account
            information and all its activities confidential. You need to
            immediately notify the Company of any unauthorized use of Your
            account. If there is reason to believe that there is likely to be a
            breach of security or misuse of Your account, we may request You to
            change the password or we may suspend Your account without any
            liability to the Company, for such period of time as we deem
            appropriate in the circumstances. The company will not be liable for
            any loss or damage arising from Your failure to comply with this
            provision. Accessibility to use Your account is dependent upon
            external factors such as internet service providers and internet
            network availability. In addition to the disclaimers set forth in
            the Terms, the Company shall not be liable to You for any damages
            arising from Your inability to log in to Your account and access the
            services of the Application due to your internet problem. Persons
            who are “competent/capable” of contracting within the meaning of the
            Indian Contract Act, 1872 shall be eligible to register for the
            Application and all Our products or Services. Minors should use Our
            products or Services, under the guidance of guardian or parents. In
            the event a minor utilizes the Application/Website/Services, it is
            assumed that he/she has obtained the consent from guardian or
            parents and terms of services are accepted by guardian or parents.
            The Company will not be responsible for any consequences that arise
            from misuse of any kind of Application or any of our products or
            services. By using the products or Services You warrant that all the
            data provided by You is accurate and complete and that the student
            using the Application has obtained the consent of the parent/legal
            guardian (in case of minors). The Company reserves the right to
            terminate your subscription and/or refuse to provide access to the
            products or services if it is found that minors are using products
            or services without the consent of a parent/legal guardian or any
            information provided by you/parent/legal guardian is inaccurate. It
            shall be Your sole responsibility to ensure that You meet the
            required qualification. Any persons under the age of 18 (eighteen)
            should seek the consent of their parents/legal guardians before
            providing any information about themselves or their parents and
            other family members on the Application.
          </p>
          <p>
            You agree to defend, indemnify and hold harmless the Company, its
            officers, directors, employees, and agents, from and against any and
            all claims, damages, obligations, losses, liabilities, costs or
            debt, and expenses (including but not limited to attorney’s fees)
            arising from (i) Your use of and access of the
            Application/Website/Services; (ii) Your violation of any term of
            these Terms or any other policy of the Company; (iii) Your violation
            of any third party right, including without limitation, any
            copyright, property, or privacy right; or (iv) any claim that Your
            use of the Application/Website/Services has caused damage to a third
            party. This defense and indemnification obligation will survive
            these Terms.
          </p>
          <p>
            In no event shall the Company, its officers, directors, employees,
            partners or agents be liable to you or any third party for any
            special, incidental, indirect, consequential or punitive damages
            whatsoever, including those resulting from loss of access, data or
            profits or any other claim arising out of, or in connection with,
            your use of, or access to, the Application.
          </p>
          <p>
            In the event of Your breach of these Terms, You agree that the
            Company will be irreparably harmed and may not have an adequate
            remedy in money or damages. The Company, therefore, shall be
            entitled in such an event to obtain an injunction against such a
            breach from any court of competent jurisdiction. The Company’s right
            to obtain such relief shall not limit its right to obtain other
            remedies.
          </p>
          <p>
            Any violation by You of the terms of this Clause may result in
            immediate suspension or termination of Your Accounts apart from any
            legal remedy that the Company can avail. In such instances, the
            Company may also disclose Your Account Information if required by
            any Governmental or legal authority. You understand that the
            violation of these Terms could also result in civil or criminal
            liability under applicable laws.
          </p>
          <p>
            The Terms shall be governed by and construed in accordance with the
            laws of India, without regard to conflict of law principles.
            Further, the Terms shall be subject to the exclusive jurisdiction of
            the competent courts located in Patan, Gujarat, and you hereby
            accept the jurisdiction of such courts. The Company has the right to
            change, modify, suspend, or discontinue and/or eliminate any
            aspect(s), features, or functionality of the Application or the
            Services as it deems fit at any time without notice. Further, the
            Company has the right to change these terms from time to time
            without prior notice to you, so you should check this page to stay
            updated about the latest terms of services. The Company makes no
            commitment, express or implied, to maintain or continue any aspect
            of the Application. You agree that the Company shall not be liable
            to You or any third party for any modification, suspension, or
            discontinuance of the Application/Services. All prices are subject
            to change without notice.
          </p>
          <ol>
            <li>
              <p>
                DISCLAIMER: THIS WEBSITE, THE APPLICATION AND THE SERVICES ARE
                PROVIDED ON AN “AS IS” BASIS WITH ALL FAULTS AND WITHOUT ANY
                WARRANTY OF ANY KIND. THE COMPANY HEREBY DISCLAIMS ALL
                WARRANTIES AND CONDITIONS WITH REGARD TO THE WEBSITE,
                APPLICATION/PRODUCTS AND THE SERVICES, INCLUDING WITHOUT
                LIMITATION, ALL IMPLIED WARRANTIES, AND CONDITIONS OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                ACCURACY, TIMELINESS. PERFORMANCE, COMPLETENESS, SUITABILITY,
                AND NON-INFRINGEMENT. ADDITIONALLY, THE COMPANY SHALL NOT BE
                LIABLE FOR ANY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE
                USE OR PERFORMANCE OF THIS SITE, OR THE APPLICATION OR THE
                SERVICES. YOUR USE OF ANY INFORMATION OR MATERIALS ON THIS
                WEBSITE/APPLICATION/SERVICES/PRODUCTS IS ENTIRELY AT YOUR OWN
                RISK, FOR WHICH WE SHALL NOT BE LIABLE. IT SHALL BE YOUR OWN
                RESPONSIBILITY TO ENSURE THAT SERVICES PROVIDED BY US MEET YOUR
                SPECIFIC REQUIREMENTS.
              </p>
            </li>
            <li>
              <p>GENERAL PROVISIONS :</p>
              <ul>
                <li>
                  <p>
                    Notice: All notices served by the Company shall be provided
                    via email to your account and registered email id from this
                    email id contact@eduthum.io
                  </p>
                </li>
                <li>
                  <p>
                    Assignment: You cannot assign or otherwise transfer Your
                    obligations under the Terms, or any right granted hereunder
                    to any third party. The Company’s rights under the Terms are
                    freely transferable by the Company to any third parties
                    without the requirement of seeking Your consent.
                  </p>
                </li>
                <li>
                  <p>
                    Waiver: Any failure by the Company to enforce or exercise
                    any provision of the Terms, or any related right, shall not
                    constitute a waiver by the Company of that provision or
                    right.
                  </p>
                </li>
                <li>
                  <p>
                    Relationship: You acknowledge that Your participation in the
                    Application does not make You an employee or agency or
                    partnership or joint venture or franchise of the Company.
                  </p>
                </li>
                <li>
                  <p>
                    The Company provides these Terms so that You are aware of
                    the terms that apply to your access to the
                    Website/Application and Services. You acknowledge that the
                    Company has given You a reasonable opportunity to review
                    these Terms and that You have agreed to them before
                    accessing our Website/Application and Services.
                  </p>
                </li>
              </ul>
            </li>
          </ol>
          <p>
            Any feedback You provide with respect to the Application shall be
            considered to be non-confidential. The Application shall be free to
            use such information on an unrestricted basis. Further, by
            submitting the feedback, You represent and warrant that
          </p>
          <p>
            Your feedback does not contain confidential or proprietary
            information of You or of third parties You are not entitled to any
            compensation or reimbursement of any kind from the Company for the
            feedback under any circumstances unless specified
          </p>
          <p>
            The company is not responsible in any manner for any content
            provided by other users even if such content is offensive, hurtful,
            or offensive. If you found such content then please report it
            immediately. Please exercise caution while accessing the Application
          </p>
          <p>
            We make all the best endeavors to provide You with a pleasant
            experience. In event that You face any issues, please contact us at
            contact@eduthum.io
          </p>
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
    </div>
  );
};
export default Terms;
