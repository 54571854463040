import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LogoImg from "../../assets/png/logoImg1.png";
import { searchCLBsAPI } from "../../redux/clb/searchCLBs";
import Style from "../../styles/header.module.scss";
import Cart from "../../views/cart/Cart";
import Notification from "../notification/Notification";
import Resources from "../resources/Resources";
import AuthMenu from "./AuthMenu";
import NavigationMenu from "./NavigationMenu";
import LoaderUI from "../../ui/LoaderUI";
import UserNotLoggedIn from "../../common/UserNotLoggedIn";
import { useGlobalState } from "../../context/GlobalContext";
import { NavLink } from "react-router-dom";
// ------------------------------\
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import HttpService from "../../config/authAxios";
import { getBlogCategories } from "../../redux/blog/topCategory";

import { useDebounce } from "../../hooks/useDebounce";

const options = [
  { text: "Home", path: "/" },
  { text: "Explore", path: "/explore" },
  { text: "Create", path: "/create" },
];

const Header = () => {
  const { globalState, setGlobalState } = useGlobalState();
  const path = useLocation().pathname;
  const getCurrentUserSelector = useSelector((state) => state?.getCurrentUser);
  const { result, loading, error } = getCurrentUserSelector;

  const searchCLBsSelector = useSelector((state) => state?.searchCLBs);
  const { result: searchresult, loading: searchResultLoading } =
    searchCLBsSelector;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState(null);
  const [notification, setNotification] = useState(false);
  const [cart, setCart] = useState(false);
  const [navMenu, setNavMenu] = useState(false);
  const [setOpen] = useState(false);
  const [authMenu, setAuthMenu] = useState(false);
  const [resMenu, setResMenu] = useState(false);
  const [userNotLoggedIn, setUserNotLoggedIn] = useState(false);
  const [closeSearch, setCloseSearch] = useState(false);
  const [ticker, setTicker] = useState("");
  const { pathname } = useLocation();
  const debouncedSearch = useDebounce(search);

  const handleMenus = (event) => {
    setAnchorEl(event.currentTarget);
    if (result) setNavMenu(true);
    else setAuthMenu(true);
  };

  const handleSearch = ({ target }) => {
    setCloseSearch(target?.value);
    setSearch(target?.value);
  };

  const handleClose = (setNavMenu) => {
    setOpen(false);
    setNavMenu(true);
    handleClose(setNavMenu);
  };

  const handleCreatCLBs = () => {
    if (error) return setUserNotLoggedIn(true);
    navigate("/create");
  };

  const handleCloseSearch = (elId, type) => {
    if (type === "clb") navigate(`/explore/${elId}`);
    if (type === "collection") navigate(`/collection/${elId}`);
    setSearch(null);
    setCloseSearch(false);
  };

  const fetchTicker = async () => {
    try {
      const response = await HttpService.get("/tickers");
      // console.log("🚀 ~ fetchTicker ~ response:==>5", response)
      setTicker(response.data.result[0].ticker);
    } catch (error) {
      console.error("Error fetching data:==>5", error);
      // throw new Error(error);
    }
  };

  useEffect(() => {
    fetchTicker();
  }, []);

  useEffect(() => {
    if (debouncedSearch) {
      dispatch(searchCLBsAPI({ text: debouncedSearch, pathname }));
    }
  }, [debouncedSearch]);

  const updateTemp = (a) => {
    // alert("asdf");
    setGlobalState({
      ...globalState,
      globalVar: "something else",
    });
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const handleHome = (e) => {
    e.preventDefault();
    navigate("/");
  };
  const handleExplorer = (e) => {
    e.preventDefault();
    navigate("/explore");
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [path]);

  useEffect(() => {
    dispatch(getBlogCategories());
  }, []);

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {options.map((value, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton>
              <NavLink to={value.path}>
                <ListItemText
                  primaryTypographyProps={{ style: { color: "black" } }}
                  primary={value.text}
                />
              </NavLink>
            </ListItemButton>
          </ListItem>
        ))}
        <Stack
          className="link"
          direction="row"
          alignItems="center"
          ml={1.8}
          spacing={0.4}
          onClick={() => setResMenu(true)}
        >
          <Typography sx={{ color: "black" }}>Resources </Typography>
          <KeyboardArrowDownRoundedIcon fontSize="medium" />
        </Stack>
      </List>
    </Box>
  );

  return (
    <>
      {/* <div className="text-white">{globalState.globalVar}</div>
      <button onClick={updateTemp} className="text-white">
        asdf
      </button> */}
      <marquee style={{ background: "#35ABAE" }}>
        <Typography variant="h6">{ticker}</Typography>
      </marquee>
      <Container style={{ marginTop: "-5px" }}>
        <Box className={Style.header}>
          <Box className={Style.container}>
            <Box className={Style.logo} onClick={() => navigate("/")}>
              <img src={LogoImg} alt="Eduthum" />
            </Box>

            <Stack
              direction="row"
              alignItems="center"
              spacing={6}
              className={Style?.navMenu}
              name="Home"
            >
              <Stack
                onClick={handleHome}
                className={pathname === "/" ? Style.headerLink : "link"}
              >
                <Typography>Home</Typography>
              </Stack>

              {/* <Link className="link" to="/">
                <Typography>Home</Typography>
              </Link> */}

              <Stack
                onClick={handleExplorer}
                className={pathname === "/explore" ? Style.headerLink : "link"}
              >
                <Typography>Explore</Typography>
              </Stack>

              <Stack
                onClick={handleCreatCLBs}
                className={pathname === "/create" ? Style.headerLink : "link"}
              >
                <Typography>Create</Typography>
              </Stack>
              <Stack
                className={pathname === "/resource" ? Style.headerLink : "link"}
                direction="row"
                alignItems="center"
                spacing={0.5}
                onClick={() => setResMenu(true)}
              >
                <Typography>Resources </Typography>
                <KeyboardArrowDownRoundedIcon fontSize="medium" />
              </Stack>
            </Stack>
            <Box className={Style.avatar}>
              <Box className={Style.searchbar}>
                <Typography>
                  <SearchRoundedIcon style={{ color: "white" }} />
                </Typography>
                <input
                  // placeholder="Search Anything Here"
                  onChange={handleSearch}
                  value={search}
                  onBlur={(e) => {
                    setSearch(null);
                  }}
                />
              </Box>

              <Box className={Style.profileMain_container}>
                <IconButton onClick={handleMenus}>
                  <Avatar
                    src={result?.picture}
                    alt="User"
                    sx={{ width: 50, height: 50, border: "1px solid #35ABAE" }}
                  />
                </IconButton>
                <Box className={Style.DrawerContainer}>
                  {["left"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button onClick={toggleDrawer(anchor, true)}>
                        <MenuIcon />
                      </Button>
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {closeSearch ? (
          <Box className={Style.searchResult}>
            {searchResultLoading ? (
              <LoaderUI />
            ) : searchresult?.length ? (
              searchresult?.map((el, idx) => {
                return (
                  <Typography
                    key={idx}
                    className={Style.searchTxt}
                    color="primary"
                    onClick={() => {
                      handleCloseSearch(el._id, el.type);
                    }}
                  >
                    {el.title ?? el.name}
                  </Typography>
                );
              })
            ) : (
              <Typography> No result found</Typography>
            )}
          </Box>
        ) : null}
      </Container>
      {/* modal */}
      {navMenu ? (
        <NavigationMenu
          setCart={setCart}
          setNotification={setNotification}
          open={navMenu}
          setOpen={setNavMenu}
          anchorEl={anchorEl}
        />
      ) : null}
      {authMenu ? (
        <AuthMenu open={authMenu} setOpen={setAuthMenu} anchorEl={anchorEl} />
      ) : null}
      {resMenu ? (
        <Resources open={resMenu} setOpen={setResMenu} anchorEl={anchorEl} />
      ) : null}
      {notification ? (
        <Notification
          open={notification}
          setOpen={setNotification}
          setNavMenu={setNavMenu}
        />
      ) : null}
      {cart ? <Cart open={cart} setOpen={setCart} /> : null}
      {userNotLoggedIn ? (
        <UserNotLoggedIn open={userNotLoggedIn} setOpen={setUserNotLoggedIn} />
      ) : null}
    </>
  );
};
export default Header;
