import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import MainRoutes from "./routes";
import muiTheme from "./theme/muiTheme";
import toast, { Toaster } from "react-hot-toast";
import { GlobalProvider } from "./context/GlobalContext";
import ErrorBoundary from "./helper/ErrorBounderies";
import { useState } from "react";
import { getFCMToken, onMessageListener } from "./config/firebase";

function App() {
  const [isTokenFound, setTokenFound] = useState(false);
  getFCMToken(setTokenFound);

  onMessageListener()
    .then((payload) => {
      toast?.success(payload.notification.body, {
        duration: 5000,
        position: "bottom-left",
      });
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <>
      <ErrorBoundary>
        <GlobalProvider>
          <ThemeProvider theme={muiTheme}>
            <Provider store={store}>
              <MainRoutes />
            </Provider>
          </ThemeProvider>
          <Toaster />
        </GlobalProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
