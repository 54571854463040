import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HttpService from "../../config/authAxios";
import { getSIngleCLBAPI } from "../clb/getSIngleCLB";

const initialState = { loading: false, result: null, error: null, message: "" };

export const placeMultipleOrdersAPI = createAsyncThunk(
  "place-multiple-orders",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await HttpService.post(`/orders/place-multiple-orders`, data);
      dispatch(getSIngleCLBAPI(data.clbId));
      return await response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const placeMultipleOrdersSlice = createSlice({
  name: "place-multiple-orders",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(placeMultipleOrdersAPI.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(placeMultipleOrdersAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload?.result;
        state.message = action.payload?.message;
      })
      .addCase(placeMultipleOrdersAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        state.message = action.payload?.message;
      });
  },
});

const placeMultipleOrdersReducer = placeMultipleOrdersSlice.reducer;

export default placeMultipleOrdersReducer;
